<template>
  <div>
    <FormGroup>
      <Button primary type="button" @click="open = !open">{{ open ? labelClosed : labelOpen }}</Button>
    </FormGroup>
    <slot v-if="open" />
  </div>
</template>

<script>
import FormGroup from './FormGroup'
import Button from './Button'
export default {
  name: 'Collapse',
  components: {
    Button,
    FormGroup
  },
  props: {
    labelOpen: String,
    labelClosed: String
  },
  data () {
    return {
      open: false
    }
  }
}
</script>
