<template>
  <form @submit.prevent="$emit('submit')">
    <slot />
    <FormError v-if="error" v-html="error" />
  </form>
</template>

<script>
import FormError from './FormError'
export default {
  name: 'Form',
  components: {
    FormError
  },
  data () {
    return {
      error: false
    }
  },
  methods: {
    setError (err) {
      this.error = err
    }
  }
}
</script>
