<template>
  <div>
    <FormGroup class="select" :required="isRequired" :v="v" :id="`dropdown-${_uid}`" :forId="`dropdown-${_uid}`" :title="label" :class="{ 'has-label': label, 'has-search': search, 'has-help': help }" :help="help">
      <Button type="button" @click="open = !open" :icon="open ? 'chevron-up' : 'chevron-down'" iconLast :class="{ error: v && v.$error }">
        <Input ref="input" @click.stop v-model="q" v-if="search && open" :placeholder="$t('Zoeken')" />
        <span v-else-if="!value">{{ $t('Selecteer') }}</span>
        <span v-else-if="multiple">{{ value.length }} {{ $t('geselecteerd') }}</span>
        <span v-else>{{ typeof selected === 'object' ? selected.label : selected }}</span>
      </Button>
      <ul class="select-options" v-if="open">
        <li v-for="(option, index) in paginatedOptions" :key="index" :class="{ multiple: multiple }">
          <Checkbox v-if="multiple" :value="typeof option === 'object' ? option.value : option" :label="typeof option === 'object' ? option.label : option" v-model="localValue" />
          <span v-if="!multiple" @click="localValue = typeof option === 'object' ? option.value : option">{{ typeof option === 'object' ? option.label : option }}</span>
        </li>
        <li v-if="hasNextPage"><span @click="currentPage++">...</span></li>
      </ul>
    </FormGroup>
    <a href="#" v-if="validSuggestion" @click.prevent="$emit('input', suggestion)">Bedoelde je <strong>{{ suggestion }}?</strong></a>
  </div>
</template>

<script>
import Vue from 'vue'
import Checkbox from './Checkbox'
import Button from './Button'
import Input from './Input'
import FormGroup from './FormGroup'
export default {
  name: 'Select',
  components: {
    FormGroup,
    Input,
    Checkbox,
    Button
  },
  props: {
    label: String,
    options: Array,
    value: [Object, Number, String, Array, Boolean],
    v: Object,
    required: [Boolean, Number],
    placeholder: String,
    help: String,
    id: String,
    suggestion: [String, Boolean],
    exclude: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      open: false,
      localValue: false,
      q: '',
      currentPage: 1
    }
  },
  mounted () {
    document.addEventListener('open-dropdown', e => {
      // If uid does not match uid in detail on event, close this one
      if (this.open && e.detail !== this._uid) {
        this.open = false
      }
    })
    if (Array.isArray(this.value)) {
      this.localValue = this.value
    } else if (this.options.includes(this.value)) {
      this.localValue = this.value
    }
    this.$watch('localValue', this.localValueWatcher)

    document.addEventListener('click', (e) => {
      if (this.open) {
        const clickTarget = document.querySelector(`#dropdown-${this._uid}`)
        if (!e.composedPath().includes(clickTarget)) {
          this.open = false
        }
      }
    })
  },
  computed: {
    search () {
      return this.options.length > 10
    },
    multiple () {
      return Array.isArray(this.value)
    },
    isRequired () {
      return this.required || (this.v && 'required' in this.v)
    },
    selected () {
      if (this.multiple) {
        return this.options.filter(option => {
          if (typeof option === 'object') return this.value.includes(option.value)
          return this.value.includes(option)
        })
      }
      return this.options.find(option => {
        if (typeof option === 'object') return this.value === option.value
        return this.value === option
      })
    },
    filteredOptions () {
      const filteredOptions = [...this.options].filter(option => {
        if (this.exclude.length) {
          if (option.value !== this.localValue && this.exclude.includes(option.value)) return false
        }
        if (this.q.length > 0) {
          const label = typeof option === 'object' ? option.label : option
          return label.toString().toLowerCase().includes(this.q.toLowerCase())
        }
        return true
      })
      if (!this.required) {
        filteredOptions.unshift({
          value: '',
          label: this.placeholder ?? this.$t('Maak een keuze')
        })
      }
      return filteredOptions
    },
    paginatedOptions () {
      return this.filteredOptions.slice(0, 10 * this.currentPage)
    },
    hasNextPage () {
      if (this.paginatedOptions.length === this.filteredOptions.length) return false
      return true
    },
    validSuggestion () {
      if (!this.suggestion) return false
      return this.options.includes(this.suggestion)
    }
  },
  methods: {
    localValueWatcher () {
      if (!Array.isArray(this.localValue)) {
        this.open = false
      }
      this.$emit('input', this.localValue)
    }
  },
  watch: {
    open () {
      if (this.open) {
        document.dispatchEvent(new CustomEvent('open-dropdown', { detail: this._uid }))
      }
      Vue.nextTick(() => {
        if (this.$refs.input && this.search && this.open) {
          this.$refs.input.$el.querySelector('input').focus()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.select {
  position: relative;
}
.select-options {
  background: var(--primary-bg);
  box-shadow: 0 3px 6px rgba(#000, 0.16);
  position: absolute;
  left: 0;
  border-radius: var(--border-radius);
  top: 3rem;
  cursor: pointer;
  z-index: 1;
  width: 100%;
  max-width: 85vw;
  max-height: 300px;
  min-width: 150px;
  overflow-y: scroll;

  li {
    transition: 300ms ease-in-out;

    > span {
      display: block;
      width: 100%;
      padding: 0.4rem 1rem;
    }

    &.multiple {
      padding: 0.4rem 1rem;
    }

    label {
      margin-bottom: 0 !important;
    }

    &:hover {
      background: var(--gray);
    }
  }
}
.select.has-label .select-options {
  top: 5rem;
}
.select.has-search.has-label .select-options {
  top: 7rem;
}
.select.has-search:not(.has-label) .select-options {
  top: 5.1rem;
}
.select.has-help .select-options {
  top: 7rem;
}
.select.has-help.has-search .select-options {
  top: 9rem;
}
</style>
