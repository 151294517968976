export const read = (cookieName) => {
  return document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)')?.pop() || ''
}

export const write = (cookieName, value, expiresInDays = 365) => {
  const d = new Date()
  d.setTime(d.getTime() + (expiresInDays * 24 * 60 * 60 * 1000))
  const expires = d.toUTCString()
  document.cookie = `${cookieName}=${value}; expires=${expires}; path=/`
}

export const remove = (cookieName) => {
  write(cookieName, null)
}
