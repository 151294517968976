<template>
  <component :is="tag" :href="href" @click.stop="clicked" :class="className" :disabled="disabled || loading"><Icon @click="clicked" v-if="iconName" :name="(loading ? 'sync' : iconName)" :animation="loading ? 'spin' : false" /><slot/></component>
</template>

<script>
import Icon from './Icon'
export default {
  name: 'Button',
  components: {
    Icon
  },
  props: {
    disabled: {
      type: Boolean
    },
    icon: {
      type: [String, Boolean]
    },
    iconLast: {
      type: Boolean
    },
    primary: {
      type: Boolean
    },
    success: {
      type: Boolean
    },
    warning: {
      type: Boolean
    },
    danger: {
      type: Boolean
    },
    full: {
      type: Boolean
    },
    fill: {
      type: Boolean
    },
    onlyIcon: {
      type: Boolean
    },
    href: {
      type: String
    },
    loading: Boolean
  },
  methods: {
    clicked () {
      if (this.disabled) return
      this.$emit('click')
    }
  },
  computed: {
    iconName () {
      if (this.icon) return this.icon
      if (this.loading) return 'sync'
      return false
    },
    tag () {
      if (this.href) {
        return 'a'
      }
      return 'button'
    },
    className () {
      const classNames = ['button']
      if (this.iconLast) {
        classNames.push('icon-last')
      }
      if (this.primary) {
        classNames.push('primary')
      }
      if (this.success) {
        classNames.push('success')
      }
      if (this.warning) {
        classNames.push('warning')
      }
      if (this.danger) {
        classNames.push('danger')
      }
      if (this.full) {
        classNames.push('full')
      }
      if (this.fill) {
        classNames.push('fill')
      }
      if (this.onlyIcon) {
        classNames.push('only-icon')
      }
      return classNames
    }
  }
}
</script>

<style lang="scss">
button,
.button {
  align-items: center;
  background: var(--primary-bg);
  padding: 0.6rem 1rem;
  border: 0;
  box-shadow: 0 1px 1px rgba(#000, 0.16);
  border-radius: var(--border-radius);
  display: inline-flex;
  font-weight: 600;
  outline: 0;
  text-transform: uppercase;
  cursor: pointer;
  transition: 300ms ease-in-out;
  font-size: 1rem;

  &:not(.only-icon):not(.icon-last) i {
    margin-right: 0.5rem;
  }

  &.icon-last i {
    order: 1;
    margin-left: 0.5rem;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.primary,
  &.success,
  &.danger,
  &.warning {
    border-width: 1px;
    border-style: solid;
  }

  &.primary {
    border-color: var(--primary-color);
    box-shadow: 0 1px 1px var(--primary-shadow-color);
    color: var(--primary-color);
  }

  &.success {
    border-color: var(--success-color);
    box-shadow: 0 1px 1px var(--success-shadow-color);
    color: var(--success-color);
  }

  &.danger {
    border-color: var(--danger-color);
    box-shadow: 0 1px 1px var(--danger-shadow-color);
    color: var(--danger-color);
  }

  &.warning {
    border-color: var(--warning-color);
    box-shadow: 0 1px 1px var(--warning-shadow-color);
    color: var(--warning-color);
  }

  &.fill {
    background: var(--gray-dark);

    &.primary,
    &.success,
    &.danger,
    &.warning {
      color: var(--primary-bg);
    }

    &.primary {
      background: var(--primary-color);
    }

    &.success {
      background: var(--success-color);
    }

    &.danger {
      background: var(--danger-color);
    }

    &.warning {
      background: var(--warning-color);
    }
  }

  &.full {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
    filter: brightness(110%);
  }

  &.error {
    border: 1px solid var(--danger-color);
  }
}
</style>
