<template>
  <Grid class="notice" :class="notice.type" :columns="{ default: 'auto 1fr' }" custom>
    <Icon style="align-self: center;" :name="icon" size="2" />
    <div>
      <strong v-if="notice.title">{{ notice.title }}</strong>
      <div v-html="notice.message"></div>
    </div>
  </Grid>
</template>

<script>
import Grid from './Grid'
import Icon from './Icon'
export default {
  name: 'Notice',
  components: {
    Icon,
    Grid
  },
  props: {
    notice: Object
  },
  computed: {
    icon () {
      if (this.notice.type === 'success') {
        return 'check-circle'
      }
      if (this.notice.type === 'info') {
        return 'info-circle'
      }
      return 'exclamation-triangle'
    }
  }
}
</script>
