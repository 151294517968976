import moment from 'moment'

export default {
  install (Vue, options) {
    Vue.filter('money', (value, sign = true) => {
      if (value === undefined || value.length === 0) {
        return value
      }
      if (isNaN(value)) {
        return value
      }
      const num = new Intl.NumberFormat('nl', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'code'
      }).format(value).replace('EUR', '').trim()
      return (sign ? '€' : '') + num
    })
    Vue.filter('period', (value, format = 'DD/MM/YYYY') => {
      if (!Array.isArray(value)) return value
      const converted = value.map(val => {
        return moment.unix(val)
      })
      if (converted.length === 1) {
        return `Vanaf ${converted[0].format(format)}`
      }
      return `Van ${converted[0].format(format)} tot ${converted[1].format(format)}`
    })
    Vue.filter('dateMonth', (value) => {
      return moment(value, 'YYYY-MM').format('MMMM YYYY')
    })
    Vue.filter('date', (value) => {
      return moment.unix(value).format('DD MMMM YYYY')
    })
  }
}
