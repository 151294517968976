import api from '../../api'
import moment from 'moment'
import { read, write, remove } from '../../utils/cookies'
import axios from 'axios'

const user = {
  namespaced: true,
  state: () => {
    return {
      user: false,
      selectedUser: false,
      familyMembers: [],
      auth: read('auth'),
      lastUnpaidOrder: false,
      DSVMembers: false,
      hasMembershipCards: false,
      federations: false,
      clubPermissions: false,
      adminURL: ''
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    auth (state) {
      return state.auth
    },
    users (state) {
      // age, birthdate, birthdate_formatted, name, uid
      let users = []
      if (state.user) users.push(state.user)
      users = [...users, ...state.familyMembers]
      return users.map(({ age, birthdate, name, uid, fname, lname, phone }) => {
        return {
          uid,
          name,
          age,
          fname,
          lname,
          phone,
          birthdate: moment.unix(birthdate)
        }
      })
    },
    selectedUser (state, getters) {
      if (!state.selectedUser) {
        return false
      }
      return getters.users.find(user => parseInt(user.uid) === parseInt(state.selectedUser))
    },
    outstandingInvoice (state) {
      return state.lastUnpaidOrder
    },
    teachers (state, getters) {
      const teachers = []
      for (const uid in state.federations) {
        const clubs = state.federations[uid]
        for (const club in clubs) {
          const member = clubs[club]
          if (member.type !== 'teacher') continue
          teachers.push({
            ...getters.users.find(user => parseInt(user.uid) === parseInt(uid)),
            membership_no: member.membership_no
          })
        }
      }
      return teachers
    },
    clubPermissions (state) {
      return state.clubPermissions
    },
    adminURL (state) {
      return state.adminURL
    }
  },
  mutations: {
    async setUser (state, user) {
      state.user = user

      if (user.auth) {
        write('auth', user.auth)
        state.auth = user.auth
        axios.defaults.headers.common.Authorization = `Bearer ${state.auth}`
      }

      if (user) {
        const response = await api.get('get_user_family_members', {
          uid: user.uid
        })
        state.familyMembers = response.data.result
        if (state.familyMembers.length === 0) {
          localStorage.setItem('selectedUser', JSON.stringify({
            loggedInUid: state.user.uid,
            uid: state.user.uid,
            timestamp: Date.now()
          }))
          state.selectedUser = state.user.uid
        }
        if (user.hasMembershipCards) {
          state.hasMembershipCards = true
        }
        if (user.clubPermissions) {
          state.clubPermissions = user.clubPermissions
        }
      } else {
        state.familyMembers = []
      }
    },
    async setDSVMembers (state, DSVMembers) {
      state.DSVMembers = DSVMembers
    },
    async setFederations (state, federations) {
      state.federations = federations
    },
    async setHasMembershipCards (state, hasMembershipCards) {
      state.hasMembershipCards = hasMembershipCards
    },
    setSelectedUser (state, uid) {
      localStorage.setItem('selectedUser', JSON.stringify({
        loggedInUid: state.user.uid,
        uid: uid,
        timestamp: Date.now()
      }))
      state.selectedUser = uid
    },
    setAuth (state, auth) {
      state.auth = auth
      write('auth', auth)
      axios.defaults.headers.common.Authorization = `Bearer ${auth}`
    },
    setLastUnpaidOrder (state, order) {
      state.lastUnpaidOrder = order
    },
    setAdminURL (state, data) {
      state.adminURL = data
    },
    updateData (state, data) {
      if (parseInt(state.user.uid) === parseInt(data.uid)) {
        if (data.fname) state.user.fname = data.fname
        if (data.lname) state.user.lname = data.lname
        state.user.name = `${state.user.fname} ${state.user.lname}`
      } else {
        const user = state.familyMembers.find(familyMember => {
          return parseInt(familyMember.uid) === parseInt(data.uid)
        })
        if (data.fname) user.fname = data.fname
        if (data.lname) user.lname = data.lname
        user.name = `${user.fname} ${user.lname}`
      }
    }
  },
  actions: {
    logout ({ commit, dispatch }) {
      remove('auth')
      commit('setUser', false)
      commit('setSelectedUser', false)
      commit('setAuth', false)
      commit('setLastUnpaidOrder', false)
      dispatch('club/setClub', false, { root: true })
      commit('club/setClubs', false, { root: true })
      dispatch('cart/empty', false, { root: true })
    }
  }
}

export default user
