<template>
  <div v-if="clubsForDropdown.length">
    <FormGroup :title="label">
      <Dropdown :items="clubsForDropdown" :value="clubTitle" @change="selectClub" primary fill>{{ selected ? selected.value : $t('Kies club') }}</Dropdown>
    </FormGroup>
  </div>
</template>

<script>
import Dropdown from './Dropdown'
import api from '../../api'
import FormGroup from './FormGroup'
export default {
  name: 'ClubDropdown',
  components: {
    FormGroup,
    Dropdown
  },
  props: {
    required: {
      default: true,
      type: Boolean
    },
    clubsList: {
      default: () => [],
      type: Array
    },
    label: String,
    mode: {
      default: 'global',
      type: String
    },
    value: String
  },
  async mounted () {
    await this.fetchData()
  },
  computed: {
    user () {
      return this.$store.getters['user/user']
    },
    club () {
      return this.$store.getters['club/club']
    },
    clubTitle () {
      if (!this.value && this.mode === 'global') return this.club.title
      if (this.clubs && this.selected && this.selected.key) return this.clubs[this.selected.key].title
      return 'Alle clubs'
    },
    clubs () {
      return this.$store.getters['club/clubs']
    },
    clubsForDropdown () {
      let values = Object.values(this.clubs).map(club => {
        return { key: club.nid, value: club.title }
      })
      if (this.clubsList.length) {
        values = values.filter((value) => {
          return this.clubsList.includes(parseInt(value.key))
        })
      }
      if (!this.required) {
        values.unshift({
          key: false, value: 'Alle clubs'
        })
      }
      return values
    },
    selected () {
      return this.clubsForDropdown.find(club => {
        if (!this.club || (this.mode && this.mode === 'local' && !this.value)) return club.key === false
        if (this.value) return (parseInt(club.key) === parseInt(this.value))
        return parseInt(club.key) === parseInt(this.club.nid)
      })
    }
  },
  methods: {
    async fetchData () {
      if (!this.clubs && this.user) {
        const clubResponse = await api.get('get_clubs_for_user')
        await this.$store.commit('club/setClubs', clubResponse.data.result)
      }
    },
    async selectClub (nid) {
      if (!nid && this.mode === 'global') {
        return this.$store.dispatch('club/setClub', false)
      }

      this.$emit('input', nid)
      const club = this.clubs[nid]

      const responseClub = await api.get('get_club_by_url', {
        url: club.url
      })
      if (this.mode === 'global') await this.$store.dispatch('club/setClub', responseClub.data.result)
    }
  },
  watch: {
    user () {
      this.fetchData()
    },
    selected: {
      handler () {
        if (this.selected) this.$emit('input', this.selected.key)
      },
      deep: true
    }
  }
}
</script>
