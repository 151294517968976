export default {
  namespaced: true,
  state: () => {
    return {
      notices: []
    }
  },
  getters: {
    notices (state) {
      return state.notices
    }
  },
  mutations: {
    addNotices (state, notices) {
      if (!Array.isArray(notices)) {
        notices = [notices]
      }

      for (const notice of notices) {
        const noticeStamped = {
          ...notice,
          timestamp: Date.now()
        }
        state.notices.push(noticeStamped)

        setTimeout(() => {
          const index = state.notices.indexOf(noticeStamped)
          state.notices.splice(index, 1)
        }, 5000)
      }
    }
  }
}
