<template>
  <label class="checkmark-container" :class="classNames">
    <input type="checkbox" :disabled="disabled" :checked="isChecked" @change="handleChange"><span class="checkmark"></span><slot /><span v-html="label"></span><span v-if="isRequired" class="required">*</span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    label: String,
    value: [String, Object],
    checked: [Array, Boolean, String, Number],
    disabled: Boolean,
    toggle: Boolean,
    v: Object
  },
  model: {
    prop: 'checked',
    event: 'input'
  },
  methods: {
    handleChange () {
      if (this.v) {
        this.v.$touch()
      }
      if (Array.isArray(this.checked)) {
        if (!this.isChecked && this.checked.indexOf(this.value) === -1) {
          this.checked.push(this.value)
        } else {
          this.checked.splice(this.checked.indexOf(this.value), 1)
        }
        this.$emit('input', this.checked)
      } else {
        this.$emit('input', !this.checked)
      }
    }
  },
  computed: {
    isRequired () {
      if (this.required || (this.v && 'required' in this.v)) {
        return true
      }
      return false
    },
    isChecked () {
      if (Array.isArray(this.checked)) {
        return this.checked.indexOf(this.value) !== -1
      }
      return this.checked
    },
    classNames () {
      const classes = []
      if (this.disabled) {
        classes.push('disabled')
      }
      if (this.toggle) {
        classes.push('toggle')
      }
      return classes
    }
  }
}
</script>
