import api from '../../api'
import { canRegister, isAgeRestricted } from '../../utils'
import { convertArrayToObject, sendEvent } from '../../utils/tagmanager'
import moment from 'moment'

const defaultOrder = {
  items: []
}

const cart = {
  namespaced: true,
  state: () => {
    return {
      tariffs: [],
      passes: [],
      order: { ...defaultOrder },
      selectedAdditionalServices: [],
      payNow: 0,
      payLater: 0,
      vouchers: [],
      hasVouchers: false,
      hasInstant: false,
      passAsPrepaid: false,
      tmpCart: false,
      sections: false,
      partsPayment: false,
      hasUitpas: false,
      uitpasNos: [],
      instant: false,
      seats: [],
      event: false,
      timer: false,
      hasPaymentOptions: false,
      relatedProducts: [],
      courseNids: [],
      partialPassUse: false,
      products: [],
      showSwitch: false,
      trials: []
    }
  },
  getters: {
    trials (state) {
      return state.trials
    },
    hasUitpas (state) {
      return state.hasUitpas
    },
    sections (state) {
      return state.sections
    },
    seats (state) {
      return state.seats
    },
    event (state) {
      return state.event
    },
    timer (state) {
      return state.timer
    },
    instant (state) {
      if (!state.instant) return false
      if (!state.instant.expires) state.instant.expires = false
      if (!state.instant.queuePosition) state.instant.queuePosition = false
      if (!state.instant.availability) state.instant.availability = false
      return state.instant
    },
    submitCartEventData (state, getters) {
      // Group same items together
      const sectionedItems = []
      for (const item of getters.cart.items) {
        if (item.section) {
          sectionedItems.push(item)
        }
      }

      const items = getters.cart.items.filter(item => {
        return !item.section
      }).map(item => {
        // Get sectioned items that match this item
        const matchingSectionedItems = sectionedItems.filter(sectionedItem => {
          return sectionedItem.nid === item.nid && sectionedItem.queue_nid === item.queue_nid
        })

        const taxonomies = {}
        for (const taxonomy in (item.tariff ? item.tariff.course.taxonomies : [])) {
          taxonomies[taxonomy] = item.tariff.course.taxonomies[taxonomy].items
        }

        const result = {
          title: item.tariff ? item.tariff.course.title : item.description,
          tariff: item.tariff ? item.tariff.title : item.description,
          quantity: item.qty + matchingSectionedItems.length,
          price: item.unit_cost,
          taxonomies: taxonomies,
          levels: item.tariff ? convertArrayToObject(item.tariff.course.levels) : []
        }
        if (item.tariff) {
          result.type = item.tariff.course.type
        }
        if (item.productVariant) {
          result.category = item.productVariant.category
          result.type = 'product'
        }
        if (item.pass) {
          result.type = 'pass'
          result.credit = item.pass.credit
        }
        if (item.discounts) {
          result.discount = item.discounts.reduce((total, currentValue) => total + currentValue.unit_cost, 0)
        }
        return result
      })
      for (const additionalService of state.selectedAdditionalServices) {
        items.push({
          title: additionalService.description.replace(' #service', '').replace(/ voor .+ \(/, ' voor xxxxx ('),
          quantity: additionalService.qty,
          price: additionalService.unit_cost,
          type: 'service'
        })
      }

      return {
        items,
        payNow: Math.round(getters.cart.payNow * 100) / 100,
        payLater: Math.round(getters.cart.payLater * 100) / 100,
        subTotal: Math.round(getters.cart.subTotal * 100) / 100,
        discount: Math.round(getters.cart.discount * 100) / 100,
        rounding: Math.round(getters.cart.rounding * 100) / 100,
        vouchers: state.vouchers
      }
    },
    hasPaymentOptions (state) {
      return state.hasPaymentOptions
    },
    tmpCart (state) {
      return state.tmpCart
    },
    partsPayment (state) {
      return state.partsPayment
    },
    courseNids (state) {
      return state.tariffs.map(tariff => tariff.course.nid)
    },
    tariffNids (state, getters) {
      let seats = []
      if (getters.seats.find(seat => seat.quantity)) {
        for (const seat of getters.seats) {
          for (let i = 0; i < seat.quantity; i++) {
            seats.push({
              t: seat.tariff
            })
          }
        }
      } else {
        seats = getters.seats.map(seat => {
          return { i: seat.nid, t: seat.tariff }
        })
      }

      if (seats.length) {
        const tariffs = []
        for (const seat of seats) tariffs.push(seat.t)
        return tariffs
      }
      return state.tariffs.map(tariff => tariff.nid)
    },
    tariffs (state) {
      return state.tariffs
    },
    users (state) {
      const multipleUsersTariffs = state.tariffs.filter(tariff => tariff.usersSimultaneous)
      const data = {}
      for (const tariff of multipleUsersTariffs) {
        for (const user of tariff.usersSimultaneous) {
          if (!data[tariff.nid]) {
            data[tariff.nid] = []
          }

          data[tariff.nid].push(user)
        }
      }
      return data
    },
    coupleCourseData (state) {
      const coupleCourseTariffs = state.tariffs.filter(tariff => {
        return tariff.coupleCourse
      })
      const data = {}
      for (const coupleCourseTariff of coupleCourseTariffs) {
        if (coupleCourseTariff.coupleCourse.coupleCourseInvite) {
          data[coupleCourseTariff.nid] = {
            cciid: coupleCourseTariff.coupleCourse.coupleCourseInvite.cciid,
            type: 'accept_invite'
          }
        } else {
          data[coupleCourseTariff.nid] = {
            type: coupleCourseTariff.coupleCourse.coupleCourseChoice,
            role: coupleCourseTariff.coupleCourse.coupleCourseChoiceRole
          }
          if (coupleCourseTariff.coupleCourse.coupleCourseChoiceData) {
            if (typeof coupleCourseTariff.coupleCourse.coupleCourseChoiceData === 'object') {
              data[coupleCourseTariff.nid] = {
                ...data[coupleCourseTariff.nid],
                fname: coupleCourseTariff.coupleCourse.coupleCourseChoiceData.firstname,
                lname: coupleCourseTariff.coupleCourse.coupleCourseChoiceData.lastname,
                mail: coupleCourseTariff.coupleCourse.coupleCourseChoiceData.email
              }
            } else {
              data[coupleCourseTariff.nid].uid = coupleCourseTariff.coupleCourse.coupleCourseChoiceData
            }
          }
        }
      }
      return data
    },
    passesData (state) {
      return state.passes.map(tariff => tariff.data)
    },
    selectedAdditionalServices (state) {
      return state.selectedAdditionalServices.map(service => {
        if (service.uid) {
          return service.nid + ';' + service.uid
        }
        return service.nid
      })
    },
    vouchers (state) {
      return Array.from(new Set(state.vouchers))
    },
    productVariantsData (state) {
      const data = {}
      for (const variant of state.products) {
        data[variant.nid] = {
          qty: parseInt(variant.qty || 0) + parseInt(variant.qty_backorder || 0)
        }
      }
      return data
    },
    courses (state) {
      const courses = []
      for (const tariff of state.tariffs) {
        if (courses.indexOf(tariff.course) === -1) {
          courses.push(tariff.course)
        }
      }
      return courses
    },
    products (state) {
      return state.products
    },
    isAddressRequired (state) {
      return state.products.filter((product) => parseInt(product.isAddressRequired) === 1).length
    },
    hasOnlyProducts (state) {
      for (const item of state.order.items) {
        if (item.type !== 'product') return false
      }
      return true
    },
    cart (state, getters, rootState, rootGetters) {
      const cart = {
        subTotal: 0,
        discount: 0,
        rounding: 0,
        partialPassUse: state.partialPassUse,
        items: [],
        payNow: state.payNow,
        payNowWithoutPass: state.payNow - state.partialPassUse,
        payLater: state.payLater,
        partsPayment: state.partsPayment,
        sections: []
      }
      for (const item of state.order.items) {
        if (item.description.includes('Afronding')) { // Roundings
          cart.rounding = item.unit_cost
        } else if (item.type === 'product') { // Products
          const cartItem = Object.assign({}, item)
          cartItem.productVariant = state.products.find(variant => parseInt(variant.nid) === parseInt(item.nid))
          if (!cartItem.productVariant) continue;
          cart.subTotal += cartItem.unit_cost * cartItem.qty
          cart.items.push(cartItem)
          cart.discount += cartItem.discount
          cart.subTotal += cartItem.discount
        } else if (item.service) { // Services
          // Find where it belongs, according to the course additional services, so we first need to find the course for this service, after that we search the first tariff and add the additional services
          const serviceGroupNid = item.nid.split('_')[0]
          const courses = getters.courses.filter(course => {
            return course.additionalServices[serviceGroupNid]
          })
          if (courses.length === 0) {
            continue
          }
          // Find listed tariff for course
          const cartItem = cart.items.find(cartItem => {
            if (cartItem.uid) return false
            const tariffs = []
            for (const course of courses) {
              for (const tariff of Object.values(course.tariffs)) {
                tariffs.push(parseInt(tariff.nid))
              }
            }
            return tariffs.includes(parseInt(cartItem.nid))
          })
          if (!cartItem) continue
          if (!cartItem.additionalServices) {
            cartItem.additionalServices = []
          }
          if (cartItem.additionalServices.indexOf(item) === -1) {
            cartItem.additionalServices.push(item)
          }
          if (item.selected) {
            cart.subTotal += item.unit_cost
          }
        } else if (item.unit_cost < 0) {
          // Discounts
          // Find cartItem with same nid as discount and add to discounts array
          const cartItem = cart.items.find(cartItem => {
            return parseInt(cartItem.nid) === parseInt(item.nid)
          })
          if (!cartItem.discounts || (cartItem.discounts && !Array.isArray(cartItem.discounts))) {
            cartItem.discounts = []
          }
          const isAdded = cartItem.discounts.find(discount => {
            return parseInt(discount.nid) === parseInt(item.nid) && parseFloat(item.unit_cost) === parseFloat(discount.unit_cost) && item.description === discount.description
          })
          if (!isAdded) {
            cartItem.discounts.push(item)
          }
        } else { // Tariffs
          item.tariff = Object.values(state.tariffs).find(tariff => parseInt(tariff.nid) === (parseInt(item.nid) || parseInt(item.queue_nid)))
          if (!item.tariff) {
            cart.items.push(item)
          } else {
            if (rootGetters['club/club'].disable_waiting_list && item.tariff.availability > 0) cart.items.push(item)
            else if (!rootGetters['club/club'].disable_waiting_list) cart.items.push(item)
          }

          // Find related sections to this tariffs
          if (Array.isArray(state.sections)) {
            for (const section of state.sections) {
              for (const sectionItem of section.items) {
                if (parseInt(sectionItem.nid || sectionItem.queue_nid) === parseInt(item.nid || sectionItem.queue_nid)) {
                  const additionalServices = section.items.filter(item => {
                    if (!item.description.includes('#service')) return false
                    const serviceGroupNid = item.nid.split('_')[0]
                    const courses = getters.courses.filter(course => {
                      return course.additionalServices[serviceGroupNid]
                    })
                    if (courses.length === 0) {
                      return false
                    }
                    const tariffs = []
                    for (const course of courses) {
                      for (const tariff of course.tariffs) {
                        tariffs.push(parseInt(tariff.nid))
                      }
                    }
                    return tariffs.includes(parseInt(sectionItem.nid))
                  })
                  for (const additionalService of additionalServices) {
                    if (parseInt(additionalService.selected)) {
                      cart.subTotal += additionalService.unit_cost
                    }
                  }
                  if (sectionItem.unit_cost < 0) { // Is discount
                    // Is discount, find cartItem with same nid as discount and add to discounts array
                    const cartItem = cart.items.find(cartItem => {
                      return parseInt(cartItem.nid) === parseInt(sectionItem.nid) && cartItem.unit_cost > 0 && cartItem.section && parseInt(section.uid) === parseInt(cartItem.section.uid)
                    })
                    if (!cartItem.discounts || !Array.isArray(cartItem.discounts)) {
                      cartItem.discounts = []
                    }
                    const isAdded = cartItem.discounts.find(discount => {
                      return parseInt(discount.nid) === parseInt(sectionItem.nid) && parseFloat(sectionItem.unit_cost) === parseFloat(discount.unit_cost) && sectionItem.description === discount.description
                    })
                    if (!isAdded) {
                      cartItem.discounts.push(sectionItem)
                    }
                  } else {
                    cart.subTotal += sectionItem.unit_cost
                    cart.items.push({
                      ...{
                        ...item,
                        ...sectionItem
                      },
                      section,
                      uid: section.uid,
                      additionalServices
                    })
                  }
                }
              }
            }
          }

          cart.subTotal += item.unit_cost * item.qty
        }
      }

      for (const item of cart.items) {
        if (!Array.isArray(item.discounts)) continue
        for (const discount of item.discounts) {
          cart.discount += discount.unit_cost
        }
      }
      if (rootGetters['user/selectedUser']) {
        cart.sections.push({
          name: rootGetters['user/selectedUser'].name,
          uid: parseInt(rootGetters['user/selectedUser'].uid)
        })
      }

      if (Array.isArray(state.sections)) {
        for (const section of state.sections) {
          cart.sections.push({
            name: section.name,
            uid: parseInt(section.uid)
          })
        }
      }

      return cart
    },
    uitpasNos (state) {
      return state.uitpasNos
    }
  },
  mutations: {
    partialPassUse (state, usedAmount) {
      state.partialPassUse = usedAmount
    },
    addTariff (state, tariff) {
      // Check if tariff already is added
      if (!state.tariffs.find(existingTariff => existingTariff.nid === tariff.nid)) {
        state.tariffs.push(tariff)
      }
      // Check for overlapping tariff
    },
    removeTariff (state, tariff) {
      const cartTariff = state.tariffs.find(cartTariff => {
        return cartTariff.nid === tariff.nid
      })
      const index = state.tariffs.indexOf(cartTariff)
      state.tariffs.splice(index, 1)
    },
    removeRelatedProduct (state, course) {
      const cartProduct = state.relatedProducts.filter(product => {
        return product.linked_course === course.nid
      })
      cartProduct.forEach((product) => {
        const index = state.relatedProducts.indexOf(product)
        state.relatedProducts.splice(index, 1)
      })
    },
    removeProduct (state, product) {
      const cartProduct = state.products.find(cartProduct => {
        return cartProduct.nid === product.nid
      })
      const index = state.products.indexOf(cartProduct)
      state.products.splice(index, 1)
    },
    addTrial (state, trial) {
      if (!state.trials.find((trialSession) => parseInt(trialSession) === parseInt(trial))) state.trials.push(parseInt(trial))
    },
    removeTrial (state, trial) {
      const cartTrial = state.trials.find(trialSession => {
        return parseInt(trialSession) === parseInt(trial)
      })
      const index = state.trials.indexOf(cartTrial)
      state.trials.splice(index, 1)
    },
    addPass (state, pass) {
      state.passes.push(pass)
    },
    removePass (state, pass) {
      const cartPass = state.passes.find(cartPass => {
        return cartPass.pass === pass.pass
      })
      const index = state.passes.indexOf(cartPass)
      state.passes.splice(index, 1)
    },
    removeSeats (state, item) {
      state.seats = state.seats.filter(seat => {
        return seat.tariff !== item.nid
      })

      if (state.seats.length === 0) state.timer = false
    },
    addProductVariant (state, variant) {
      // Find product variant, see if it exists, if not add, else increase qty
      if (variant.backorder === undefined) variant.backorder = 0
      const productVariant = state.products.find(existingProductVariant => existingProductVariant.nid === variant.nid && variant.backorder === existingProductVariant.backorder)
      if (productVariant) {
        productVariant.qty++
      } else {
        state.products.push({ ...variant, qty: variant.qty || 1, qty_backorder: variant.qty_backorder })
      }
    },
    addCourse (state, course) {
      // Check if course already is added
      if (!state.courses.find(existingCourse => existingCourse.nid === course.nid)) {
        state.courses.push(course)
      }
    },
    setOrder (state, order) {
      state.order = order
    },
    addAdditionalService (state, service) {
      const existingService = state.selectedAdditionalServices.find(existingService => {
        return existingService.nid === service.nid && parseInt(existingService.uid) === parseInt(service.uid)
      })
      if (!existingService) {
        state.selectedAdditionalServices.push(service)
        // Trigger tag manager
        sendEvent('add-to-cart', {
          type: 'service',
          title: service.description.replace(' #service', '').replace(/ voor .+ \(/, ' voor xxxxx ('),
          price: service.unit_cost,
          quantity: service.qty
        })
      }
    },
    removeAdditionalService (state, service) {
      const existingServiceIndex = state.selectedAdditionalServices.findIndex((existingService) => {
        return existingService.nid === service.nid && existingService.uid === service.uid
      })
      if (existingServiceIndex !== -1) {
        state.selectedAdditionalServices.splice(existingServiceIndex, 1)
      }
    },
    setPayNowPayLater (state, { payNow, payLater }) {
      state.payNow = payNow
      state.payLater = payLater
    },
    addVoucher (state, voucher) {
      state.vouchers.push(voucher)
    },
    removeVoucher (state, voucher) {
      const index = state.vouchers.indexOf(voucher)
      state.vouchers.splice(index, 1)
    },
    setCart (state, cart) {
      state.tariffs = cart.tariffs
      state.vouchers = cart.vouchers
      state.products = cart.products
      state.selectedAdditionalServices = cart.selectedAdditionalServices
      state.passes = cart.passes
      state.trials = cart.trials
    },
    setQuantityProduct (state, data) {
      const variant = state.products.find(variant => {
        return parseInt(variant.nid) === parseInt(data.item.nid)
      })
      if (data.item.backorder) variant.qty_backorder = data.qty
      else variant.qty = data.qty
    },
    setHasVouchers (state, status) {
      state.hasVouchers = status
    },
    setHasInstant (state, status) {
      state.hasInstant = status
    },
    setPassAsPrepaid (state, status) {
      state.passAsPrepaid = status
    },
    setTmpCart (state, cart) {
      state.tmpCart = cart
    },
    setSections (state, sections) {
      delete sections.enabled
      state.sections = Object.values(sections)
    },
    setPartsPayment (state, data) {
      state.partsPayment = data
    },
    setUitpasNos (state, nos) {
      state.uitpasNos = nos
    },
    setHasUitpas (state, status) {
      state.hasUitpas = status
    },
    setInstant (state, data) {
      state.instant = data
    },
    setSeats (state, data) {
      state.seats = data
    },
    setEvent (state, event) {
      state.event = event
    },
    setTimer (state, expires) {
      state.timer = expires
    },
    setHasPaymentOptions (state, status) {
      state.hasPaymentOptions = status
    },
    setRelatedProducts (state, products) {
      state.relatedProducts = products
    },
    switch (state, status) {
      state.showSwitch = !state.showSwitch
    }
  },
  actions: {
    switch ({ commit }) {
      commit('switch', true)
    },
    instant ({ commit }, data) {
      if (data.expires) data.expires = moment(data.expires)
      commit('setInstant', data)
    },
    restartTimer ({ commit }) {
      commit('setTimer', moment().add(15, 'm'))
    },
    setQuantity ({ commit, dispatch }, data) {
      if (data.item.type === 'product') {
        commit('setQuantityProduct', data)
      }
      dispatch('recalculate')
    },
    async empty ({ commit, dispatch }) {
      const cart = this.getters['cart/cart']
      commit('setTmpCart', cart)
      commit('setCart', {
        tariffs: [],
        passes: [],
        products: [],
        selectedAdditionalServices: [],
        vouchers: [],
        sections: false,
        trials: []
      })
      commit('setSeats', [])
      dispatch('recalculate')
    },
    async transfer ({ commit, dispatch }) {
      // Todo check cart items if applicable to carry over (age, already registered, ..)
      const cart = this.getters['cart/cart']
      for (const item of cart.items) {
        let removeItem = false
        if (item.tariff) {
          if (isAgeRestricted(this.getters['user/selectedUser'], item.tariff.course)) {
            removeItem = true
            commit('notice/addNotices', {
              title: 'Cursus verwijderd',
              message: `Je leeftijd valt buiten de restricties voor <strong>${item.tariff.course.title} (${item.tariff.title})</strong>.`,
              type: 'warning'
            }, { root: true })
          }
          if (!canRegister(this.getters['user/selectedUser'], item.tariff)) {
            removeItem = true
            commit('notice/addNotices', {
              title: 'Cursus verwijderd',
              message: `Je ben reeds geregistreerd voor <strong>${item.tariff.course.title} (${item.tariff.title})</strong>.`,
              type: 'warning'
            }, { root: true })
          }
        }
        if (removeItem) {
          dispatch('removeItem', item)
        }
      }
    },
    async restoreCart ({ commit, dispatch }) {
      // const cart = JSON.parse(localStorage.getItem('cart'))
      // if (cart) {
      //   await commit('setCart', cart)
      //   await commit('user/setSelectedUser', cart.uid, { root: true })
      //
      //   if (this.state.club.club && this.state.club.club.url === cart.club) {
      //     dispatch('recalculate')
      //   }
      // }
    },
    async recalculate ({ commit, dispatch }, overwriteData) {
      if (this.getters['cart/seats'].length) {
        let seats = []
        if (this.getters['cart/seats'].find(seat => seat.quantity)) {
          for (const seat of this.getters['cart/seats']) {
            for (let i = 0; i < seat.quantity; i++) {
              seats.push({
                t: seat.tariff
              })
            }
          }
        } else {
          seats = this.getters['cart/seats'].map(seat => {
            return { i: seat.nid, t: seat.tariff }
          })
        }

        const response = await api.post('get_ticket_sales_quote', {
          club: this.state.club.club.nid,
          uid: this.state.user.user.uid,
          event: this.state.cart.event.nid,
          seats,
          mode: 'order'
        })
        // Group seats by tariff
        const itemsByTariff = {}
        for (const item of response.data.orderItems) {
          if (!itemsByTariff[item.nid]) {
            itemsByTariff[item.nid] = item
          } else {
            itemsByTariff[item.nid].qty++
          }
        }
        commit('setPayNowPayLater', {
          payNow: response.data.total,
          payLater: 0
        })
        commit('setHasPaymentOptions', response.data.has_payment_options)
        let hasInstant = false
        for (const item of response.data.orderItems) {
          if (item.has_instant) hasInstant = true
        }
        commit('setHasInstant', hasInstant)
        return commit('setOrder', {
          items: Object.values(itemsByTariff)
        })
      }
      if (this.getters['cart/tariffNids'].length || this.getters['cart/passesData'].length || Object.keys(this.getters['cart/productVariantsData']).length) {
        const response = await api.post('compile_public_registration_invoice', {
          club: this.state.club.club.nid,
          uid: this.state.user.selectedUser,
          tariffs: this.getters['cart/tariffNids'],
          courses: this.getters['cart/courseNids'],
          passes: this.getters['cart/passesData'],
          products: this.getters['cart/productVariantsData'],
          selectedAdditionalServices: this.getters['cart/selectedAdditionalServices'],
          vouchers: this.getters['cart/vouchers'],
          coupleCourse: this.getters['cart/coupleCourseData'],
          users: this.getters['cart/users'],
          birthdate: overwriteData?.birthdate,
          uitpasNos: this.getters['cart/uitpasNos'],
          trials: this.getters['cart/trials']
        }, {
          loading: true
        })
        commit('setHasPaymentOptions', response.data.has_payment_options)
        commit('setHasVouchers', response.data.has_vouchers)
        commit('setRelatedProducts', response.data.result.related_products)
        commit('setPassAsPrepaid', response.data.passAsPrepaid)
        let hasInstant = false
        let tariffsUsed = []
        let trialsUsed = []
        for (const item of response.data.result.items) {
          if (item.reserved_session && !trialsUsed.includes(item.reserved_session.nid)) trialsUsed.push(item.reserved_session.nid)
          if (item.tariff && !tariffsUsed.includes(item.nid)) tariffsUsed.push(item.tariff.nid)
          if (item.has_instant) hasInstant = true
          if (item.type === 'product') {
            const itemsForNid = response.data.result.items.filter((oriItem) => oriItem.nid === item.nid)
            if (itemsForNid && itemsForNid.length > 1) {
              commit('setQuantityProduct', {
                item,
                qty: item.qty
              })
            }
          }
        }

        for (const trialSessionId of this.getters['cart/trials']) {
          if (!trialsUsed.includes(trialSessionId)) commit('removeTrial', trialSessionId)
        }
        for (const tariff of this.getters['cart/tariffs']) {
          if (tariff.type === 'trial' && !tariffsUsed.includes(tariff.nid)) {
            commit('removeTariff', tariff)
          }
        }

        commit('setHasInstant', hasInstant)
        commit('setOrder', response.data.result)
        commit('setPayNowPayLater', {
          payNow: response.data.payNow,
          payLater: response.data.payLater
        })
        commit('setHasUitpas', response.data.has_uitpas)
        if (response.data.result.partsSummary) {
          commit('setPartsPayment', {
            partsDeposit: response.data.result.partsDeposit,
            partsPayNow: response.data.result.partsPayNow,
            partsSummary: response.data.result.partsSummary,
            partsSummaryWithoutDiscount: response.data.result.partsSummaryWithoutDiscount,
            partsTotal: response.data.result.partsTotal
          })
        } else {
          commit('setPartsPayment', false)
        }
        if (response.data.sections) {
          commit('setSections', response.data.sections)
        }
        if (parseInt(response.data.result.has_instant)) {
          commit('setTimer', moment().add(15, 'm'))
        }

        // Save data to localStorage
        // localStorage.setItem('cart', JSON.stringify({
        //   uid: this.state.user.selectedUser,
        //   club: this.state.club.club.url,
        //   tariffs: this.state.cart.tariffs,
        //   passes: this.state.cart.passes,
        //   products: this.state.cart.productVariants,
        //   selectedAdditionalServices: this.state.cart.selectedAdditionalServices,
        //   vouchers: this.state.cart.vouchers
        // }))

        if (response.data?.result?.vouchers?.length && Array.isArray(response.data.result.vouchers)) {
          for (const voucher of response.data.result.vouchers) {
            dispatch('removeVoucher', voucher)
          }
          commit('notice/addNotices', {
            title: 'Kortingscode niet geldig',
            message: 'Deze kortingscode is niet geldig of kan niet gecombineerd worden en werd verwijderd uit je winkelmandje.',
            type: 'warning'
          }, { root: true })
        } else if (response.data?.result?.user_feedback[0]?.includes('op voorraad')) {
          commit('notice/addNotices', {
            title: 'Niet genoeg voorraad',
            message: response.data.result.user_feedback[0],
            type: 'warning'
          }, { root: true })
        } else if (response.data?.result?.user_feedback[0]?.includes('UiTPAS')) {
          commit('notice/addNotices', {
            title: 'UiTPAS probleem',
            message: response.data.result.user_feedback[0],
            type: 'warning'
          }, { root: true })
        }
      } else {
        commit('setOrder', defaultOrder)
        commit('setPayNowPayLater', {
          payNow: 0,
          payLater: 0
        })
        localStorage.removeItem('cart')
      }
    },
    async addVoucher ({ commit, dispatch }, voucher) {
      if (voucher) {
        commit('addVoucher', voucher)
        dispatch('recalculate')
      }
    },
    async removeVoucher ({ commit, dispatch }, voucher) {
      commit('removeVoucher', voucher)
      dispatch('recalculate')
    },
    async addItem ({ commit, dispatch }, data) {
      console.log({
        data
      })
      if (data.tariff) {
        const tariff = Object.assign({}, data.tariff)
        if (data.course) {
          tariff.course = Object.assign({}, data.course)
          delete tariff.course.content
        }
        if (data.coupleCourse) {
          tariff.coupleCourse = Object.assign({}, data.coupleCourse)
        }
        if (data.usersSimultaneous) {
          tariff.usersSimultaneous = [...data.usersSimultaneous]
        }
        if (data.tariff.type === 'trial' && data.session) {
          await commit('addTrial', data.session)
        }
        await commit('addTariff', tariff)

        const taxonomies = {}
        for (const taxonomy in data.course.taxonomies || []) {
          taxonomies[taxonomy] = data.course.taxonomies[taxonomy].items
        }

        // Trigger tag manager
        sendEvent('add-to-cart', {
          type: data.course.type,
          title: data.course.title,
          tariff: data.tariff.title,
          price: Math.round((data.tariff.discountedPrice || data.tariff.price) * 100) / 100,
          taxonomies: taxonomies || {},
          levels: convertArrayToObject(data.course.levels || []),
          quantity: tariff.usersSimultaneous ? tariff.usersSimultaneous.length : tariff.coupleCourse && tariff.coupleCourse.coupleCourseChoice === 'family' ? 2 : 1
        })
      }

      if (data.pass) {
        await commit('addPass', data.pass)
        // Trigger tag manager
        sendEvent('add-to-cart', {
          type: 'pass',
          title: data.pass.label,
          credit: Math.round(data.pass.credit * 100) / 100,
          price: Math.round(data.pass.price * 100) / 100,
          quantity: 1
        })
      }

      if (data.product && data.variant) {
        const variant = Object.assign({}, data.variant)
        variant.product = data.product
        variant.qty = data.quantity || 1
        variant.qty_backorder = data.qty_backorder
        await commit('addProductVariant', variant)

        sendEvent('add-to-cart', {
          type: 'product',
          title: data.product.title,
          variant: data.variant.title,
          price: Math.round(data.variant.price * 100) / 100,
          category: data.product.category,
          quantity: data.quantity || 1
        })
      }

      // Ticket sale with planner
      if (data.seats) {
        await commit('setSeats', data.seats)
        await commit('setEvent', data.event)

        // Set expires on
        let lowestTime
        for (const seat of data.seats) {
          if (!lowestTime) lowestTime = seat.expires
          if (lowestTime > seat.expires) lowestTime = seat.expires
        }
        if (lowestTime) {
          await commit('setTimer', moment(lowestTime * 1000))
        }
      }

      await dispatch('recalculate', data.additionalServiceListing)
    },
    async removeItem ({ commit, dispatch }, item) {
      if (item.tariff) {
        if (item.tariff.type === 'trial') {
          commit('removeTrial', item.reserved_session.nid)
          if (!this.getters['cart/trials'].length) {
            commit('removeTariff', item.tariff)
            commit('removeRelatedProduct', item.tariff.course)
          }
        } else {
          commit('removeTariff', item.tariff)
          commit('removeRelatedProduct', item.tariff.course)
        }
      } else if (item.productVariant) {
        commit('removeProduct', item.productVariant)
      } else if (item.pass) {
        commit('removePass', item.pass)
      } else if (item.meta && item.meta.seat) {
        commit('removeSeats', item)
      }

      dispatch('recalculate')
    }
  }
}

export default cart
