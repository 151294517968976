<template>
  <div>
    <slot name="trigger" :toggle="toggle">
      <Button type="button" v-if="button" @click="toggle" primary>{{ button }}</Button>
    </slot>
    <transition name="fade">
      <div ref="modal" class="modal" v-if="open" @click.stop>
        <Card class="modal-content" :class="{ 'small': small }">
          <Icon v-if="closable" class="modal-close" name="times" @click="closeManually" />
          <span class="modal-title">{{ title }}</span>
          <slot :closeManually="closeManually" />
        </Card>
      </div>
    </transition>
  </div>
</template>

<script>
import Card from './Card'
import Icon from './Icon'
import Button from './Button'
export default {
  name: 'Modal',
  components: {
    Button,
    Icon,
    Card
  },
  props: {
    title: [String, Boolean],
    button: String,
    buttonClass: String,
    timeout: String,
    closable: {
      type: Boolean,
      default: true
    },
    small: Boolean
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    toggle () {
      this.open = !this.open
    },
    show () {
      this.open = true
    },
    hide () {
      this.open = false
    },
    closeManually () {
      this.open = false
      this.$emit('close-manually')
    },
    toggleScrollParentModal () {
      if (this.$refs.modal && this.$refs.modal.closest('.modal .modal-content')) this.$refs.modal.closest('.modal .modal-content').style.overflowY = this.open ? 'clip' : 'scroll'
    },
    toggleParentModal () {
      if (this.$refs.modal) {
        this.$parent.$parent.$parent.$parent.$parent.$parent.closeManually()
      }
    }
  },
  watch: {
    open () {
      this.$nextTick(() => {
        this.toggleScrollParentModal()
        if (this.timeout) {
          setTimeout(() => {
            this.toggleParentModal()
            this.hide()
          }, this.timeout)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.35);
  height: 100%;
  z-index: 2;
  cursor: default;
}
.modal-content {
  background: var(--primary-bg);
  padding: 2rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  width: auto;
  min-width: max(300px, 40vw);
  box-shadow: 0 0 10px rgba(#000, 0.1);
  z-index: 2;
  text-align: left;
  max-height: 95%;
  overflow-y: scroll;

  .modal-title {
    font-size: 1.2rem;
    display: block;
    margin-bottom: 1rem;
  }

  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 1rem;

    &:hover {
      color: var(--primary-color);
    }
  }

  &.small {
    min-width: min(400px, 40vw);
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 300ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
