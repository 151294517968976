export function sendEvent (name, extraData = {}) {
  if (!window.dataLayer) return false
  window.dataLayer.push({ event: name, ...extraData })
}

export function convertArrayToObject (array) {
  const object = {}
  for (const item of array) {
    if (!object[item.category.title]) object[item.category.title] = []
    object[item.category.title].push(item.title)
  }
  return object
}
