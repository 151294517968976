<template>
  <div class="add-to-home-ios" v-if="show">
    <strong>{{ $t('Deze app toevoegen aan je beginscherm') }}:</strong>
    <p>Druk op <img src="@/assets/ios-share.png" width="16px" style="margin: 0 0.5rem;"> en zet op beginscherm.</p>
    <Icon class="add-to-home-ios-close" name="times" @click="show = false" />
  </div>
</template>

<script>
import Icon from '../Core/Icon'
export default {
  name: 'AddToHomeIos',
  components: {
    Icon
  },
  data () {
    return {
      show: false
    }
  },
  mounted () {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone)

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.show = true
    }
  }
}
</script>

<style lang="scss">
.add-to-home-ios {
  background: var(--gray);
  font-size: 85%;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);

  p {
    margin: 0;
  }

  .add-to-home-ios-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.3rem;
  }
}
</style>
