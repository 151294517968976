<template>
  <div class="alert" :class="classNames"><div class="title" v-if="title">{{ title }}</div><slot/></div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    type: {
      type: String,
      default: 'warning'
    },
    title: String
  },
  computed: {
    classNames () {
      const classes = [this.type]
      return classes
    }
  }
}
</script>

<style lang="scss">
.alert {
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: var(--border-radius);
  background: var(--warning-bg-color);
  border: 1px solid var(--gray-darker);

  &.danger {
    background: var(--danger-color);
    color: #fff;
  }

  &.success {
    background: var(--success-color);
    color: #fff;
  }

  &.info {
    background: var(--primary-color);
    color: #fff;
  }

  a {
    font-weight: bold;
    color: var(--white);
    text-decoration: underline;
  }
}
.title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
</style>
