<template>
  <div v-if="mounted">
    <span class="text-muted" v-if="specificInline && field.nid">{{ field.course_specific_info }}</span>
    <FormGroup v-if="field.type === 'radio'" :title="field.question" :required="isRequired" :v="v" :help="field.help">
      <Radio v-for="(option, index) in field.options" :v="v" :label="option" :key="index" v-model="localValue" :value="option" />
    </FormGroup>
    <Select v-else-if="['select', 'select_multiple'].includes(field.type)" :options="field.options" :v="v" :label="field.question" v-model="localValue" :help="field.help" />
    <Nationality v-else-if="field.default_key === 'nationality'" v-model="localValue" :v="v" :label="field.question" :required="isRequired" :help="field.help" />
    <Input v-else-if="field.type === 'text'" v-model="localValue" :v="v" :label="field.question" :required="isRequired" :help="field.help" :placeholder="field.placeholder" />
    <Input v-else-if="field.type === 'email'" type="email" :label="field.question" v-model="localValue" :v="v" :required="isRequired" :help="field.help" :placeholder="field.placeholder" />
    <Address v-else-if="field.type === 'address'" ref="address" :label="field.question" v-model="localValue" :v="v" :required="isRequired" :help="field.help" />
    <Phone v-else-if="field.type === 'phone'" :label="field.question" v-model="localValue" :v="v" :required="isRequired" :help="field.help" />
    <FormGroup v-else-if="field.type === 'checkbox' || field.type === ''" :v="v" :help="field.help">
      <Checkbox v-model="localValue" :v="v" :label="field.question" :required="isRequired" />
    </FormGroup>
    <FilesInput
      v-else-if="field.type === 'image' || field.type === 'file'"
      :label="field.question"
      :help="field.help"
      :dropzoneOptions="dropzoneOptions"
      v-model="localValue"
      :required="isRequired"
      :v="v"
    />
    <InputArea v-else-if="field.type === 'textarea'" v-model="localValue" :v="v" :label="field.question" :required="isRequired" :help="field.help" :placeholder="field.placeholder" />
    <div v-else>
      {{ value }}
    </div>
  </div>
</template>

<script>
import Radio from '../Core/Radio'
import FormGroup from '../Core/FormGroup'
import Select from '../Core/Select'
import Input from '../Core/Input'
import Checkbox from '../Core/Checkbox'
import Address from '../Core/Address'
import FilesInput from '../Core/FilesInput'
import InputArea from '../Core/InputArea'
import Nationality from '../Core/Nationality'
import Phone from '../Core/Phone'
export default {
  name: 'Field',
  components: {
    Phone,
    Nationality,
    InputArea,
    FilesInput,
    Address,
    Checkbox,
    Input,
    Select,
    FormGroup,
    Radio
  },
  props: {
    value: [String, Object, Array, Boolean],
    v: Object,
    field: Object,
    specificInline: Boolean
  },
  mounted () {
    if (this.value) {
      if (this.field.type === 'select_multiple') {
        this.localValue = typeof this.value === 'string' ? this.value.split(',') : Array.isArray(this.value) ? this.value : []
      } else {
        this.localValue = this.value
      }
    } else if (this.field.type === 'select_multiple') {
      this.localValue = []
    } else if (this.field.type === 'checkbox') {
      this.localValue = false
    }
    this.mounted = true
  },
  data () {
    return {
      localValue: '',
      mounted: false
    }
  },
  computed: {
    isRequired () {
      return parseInt(this.field.required) === 1 || this.field.required === true
    },
    selectedUser () {
      return this.$store.getters['user/selectedUser']
    },
    dropzoneOptions () {
      const config = {
        endpoint: 'save_question_files',
        params: {
          type: 'answer',
          qid: this.field.qid,
          nid: this.field.nid,
          uid: this.selectedUser.uid
        }
      }
      if (this.field.config) {
        if (this.field.config.max) {
          config.maxFiles = this.field.config.max
        }
      }
      return config
    }
  },
  methods: {
    refresh () {
      if (this.$refs.address) this.$refs.address.refresh()
    }
  },
  watch: {
    localValue: {
      handler () {
        this.$emit('input', this.localValue)
      },
      deep: true
    },
    value () {
      this.localValue = this.value
    }
  }
}
</script>
