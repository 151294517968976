<template>
  <div class="grid" :class="classNames" :style="computedStyles" @click="$emit('click')">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Grid',
  props: {
    columns: {
      type: Object
    },
    gap: {
      type: String,
      default: '1rem'
    }
  },
  computed: {
    classNames () {
      const classes = []
      if (this.columns) {
        for (const screen in this.columns) {
          const columns = this.columns[screen]
          if (!isNaN(columns)) {
            classes.push(`${screen}-columns-${columns}`)
          } else {
            classes.push(`${screen}-columns-custom`)
          }
        }
      }
      return classes
    },
    computedStyles () {
      const styles = {}
      if (this.columns) {
        for (const screen in this.columns) {
          const columns = this.columns[screen]
          if (isNaN(columns)) {
            styles[`--${screen}-grid`] = columns
          }
        }
      }
      if (this.gap) {
        styles['--grid-gap'] = this.gap
      }
      return styles
    }
  }
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-gap: var(--grid-gap);
}

$columns: 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

@each $column in $columns {
  .default-columns-#{$column} {
    grid-template-columns: repeat(#{$column}, 1fr);
  }
  .default-columns-custom {
    grid-template-columns: var(--default-grid);
  }
}

@media screen and (min-width: 768px) {
  @each $column in $columns {
    .tablet-columns-#{$column} {
      grid-template-columns: repeat(#{$column}, 1fr);
    }
    .tablet-columns-custom {
      grid-template-columns: var(--tablet-grid);
    }
  }
}

@media screen and (min-width: 1100px) {
  @each $column in $columns {
    .desktop-columns-#{$column} {
      grid-template-columns: repeat(#{$column}, 1fr);
    }
    .desktop-columns-custom {
      grid-template-columns: var(--desktop-grid);
    }
  }
}
</style>
