import api from '../api'

export default {
  install (Vue, options) {
    Vue.prototype.$thumbnail = (club, fid, format) => {
      if (!fid) {
        return `${api.getUrl()}?q=render_preview&club=${club}&url=placeholder&format=${format}`
      }
      return `${api.getUrl()}?q=render_preview&club=${club}&fid=${fid}&format=${format}`
    }
  }
}
