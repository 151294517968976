<template>
  <FormGroup :v="v" :hideError="hideError" slim>
    <label class="checkmark-container" :class="classNames">
      <input ref="radio" type="radio" :value="value" @change.prevent="handleChange" :checked="isChecked" :disabled="disabled" /><span class="checkmark"></span>
      {{ label }}
      <slot />
    </label>
  </FormGroup>
</template>

<script>
import FormGroup from './FormGroup'
export default {
  name: 'Radio',
  components: { FormGroup },
  props: {
    label: String,
    value: [String, Number, Object],
    disabled: Boolean,
    checked: [String, Boolean, Object],
    locked: Boolean,
    flex: Boolean,
    v: Object,
    hideError: Boolean
  },
  model: {
    prop: 'checked',
    event: 'input'
  },
  methods: {
    handleChange () {
      if (!this.disabled) {
        if (this.v) {
          this.v.$touch()
        }
        this.$emit('input', this.value)
      }
    }
  },
  computed: {
    isChecked () {
      return this.value === this.checked
      // return this.value === this.checked && (this.value.length > 0 || (typeof this.value === 'number' && this.value > 0) || (typeof this.value === 'object' && Object.keys(this.value).length > 0))
    },
    classNames () {
      const classes = []
      if (this.disabled) {
        classes.push('disabled')
      }
      if (this.locked) {
        classes.push('locked')
      }
      if (this.flex) {
        classes.push('flex')
      }
      return classes
    }
  }
}
</script>
