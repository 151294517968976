<template>
  <div>
    <h3>{{ title }}</h3>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Section',
  props: {
    title: String
  }
}
</script>
