<template>
  <div class="combined-input">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CombinedInput'
}
</script>

<style lang="scss">
.combined-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 0.3rem;
  }
}
</style>
