<template>
  <FormGroup :title="label" :titleSuffix="labelSuffix" :required="isRequired" :help="help" :forId="id">
    <v-date-picker :value="value" :modelConfig="modelConfig" :masks="{ input: 'DD/MM/YYYY' }" @input="handleInput" :is-required="isRequired" :popover="{ positionFixed: fixed, placement: top ? 'top-start' : 'bottom-start' }">
      <template v-slot="{ inputValue, inputEvents }">
        <input
          :id="id"
          :class="{ error: v && v.$error }"
          :value="inputValue"
          :placeholder="placeholder"
          v-on="inputEvents"
        />
      </template>
    </v-date-picker>
    <FormError v-if="v && v.$error" :v="v" />
  </FormGroup>
</template>

<script>
import FormGroup from './FormGroup'
import FormError from './FormError'
export default {
  name: 'Datepicker',
  components: {
    FormError,
    FormGroup
  },
  props: {
    value: [Number, String],
    label: String,
    labelSuffix: String,
    required: Boolean,
    v: Object,
    fixed: Boolean,
    top: Boolean,
    modelConfig: {
      type: Object,
      default: () => {
        return {
          type: 'string', mask: 'DD/MM/YYYY'
        }
      }
    },
    help: String,
    placeholder: {
      default: 'DD/MM/YYYY',
      type: String
    },
    id: String
  },
  computed: {
    isRequired () {
      return this.required || (this.v && 'required' in this.v)
    }
  },
  methods: {
    handleInput (e) {
      if (this.v) {
        this.v.$touch()
      }
      this.$emit('input', e)
    }
  }
}
</script>
