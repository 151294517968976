<template>
  <div class="form-help"><slot /></div>
</template>

<script>
export default {
  name: 'FormHelp'
}
</script>

<style>
.form-help {
  color: var(--gray-darker);
  font-size: 85%;
  display: block;
  margin: 4px 0;
}
</style>
