export default {
  namespaced: true,
  state: () => {
    return {
      feedback: []
    }
  },
  getters: {
    all (state) {
      return state.feedback
    }
  },
  mutations: {
    clear (state) {
      state.feedback = []
    },
    add (state, feedback) {
      state.feedback.push(feedback)
    }
  },
  actions: {
    clear ({ commit }) {
      commit('clear')
    },
    add ({ commit }, feedbackArray) {
      if (Array.isArray(feedbackArray)) {
        for (const feedback of feedbackArray) {
          commit('add', feedback)
        }
      } else {
        commit('add', feedbackArray)
      }
    }
  }
}
