<template>
  <div class="column" :style="style">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Column',
  props: {
    span: {
      type: Number,
      default: 1
    }
  },
  computed: {
    style () {
      return {
        gridColumn: `span ${this.span}`
      }
    }
  }
}
</script>
